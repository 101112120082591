import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from 'react-router-dom';  // Import useLocation from react-router
import '../styles/SearchBar.css';

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState('');
  const location = useLocation();  // Get the current route location

  // Clear the search input when the route changes, but keep it on the search page
  useEffect(() => {
    // Check if the user is NOT on the search page
    if (location.pathname !== '/search') {
      setQuery('');  // Reset the query when navigating away from the search page
    }
  }, [location]);

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(query);  // Trigger the search with the query
  };

  return (
    <Box
      component="form"
      onSubmit={handleSearch}
      className="search-bar-container"
    >
      <TextField
        variant="outlined"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="search-input"
        fullWidth  // Ensure full width for TextField
      />
      <IconButton type="submit" className="search-button">
        <SearchIcon />
      </IconButton>
    </Box>
  );
}

export default SearchBar;
