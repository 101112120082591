import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/MobileUpload.css'; // Add CSS specific to mobile upload

const MobileUpload = ({ onUpload }) => {
  const [file, setFile] = useState(null); // State to hold selected file
  const [description, setDescription] = useState(''); // State to hold description
  const [isUploading, setIsUploading] = useState(false); // Track upload state
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = () => {
    if (!file) {
      alert('Please select a file.');
      return;
    }

    setIsUploading(true); // Set uploading state

    const formData = new FormData();
    formData.append('video', file);
    formData.append('description', description);

    // Simulate an upload process
    onUpload({ target: { files: [file], formData } });

    setTimeout(() => {
      setIsUploading(false); // End loading state
      navigate('/'); // Redirect back to home after upload
    }, 2000);
  };

  return (
    <div className="mobile-upload-container">
      <h2>Upload Video</h2>

      {/* File Input Area */}
      <div className="file-select-area">
        <label className="file-label" htmlFor="file-upload">
          {file ? `File selected: ${file.name}` : 'Click to select a video'}
        </label>
        <input
          id="file-upload"
          type="file"
          accept="video/*"
          className="file-input"
          onChange={handleFileChange}
        />
      </div>

      {/* Description Input */}
      <textarea
        className="description-input"
        placeholder="Add a description..."
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>

      {/* Upload Button */}
      <button
        className={`upload-btn ${isUploading ? 'loading' : ''}`}
        onClick={handleUpload}
        disabled={isUploading}
      >
        {isUploading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  );
};

export default MobileUpload;
