import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import '../styles/UploadModal.css';

const UploadModal = ({ onUpload, closeModal }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [description, setDescription] = useState(''); // Description state
  const [file, setFile] = useState(null); // Uploaded file state

  // Handle drag-and-drop
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'video/*'
  });

  const handleUploadSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please select a file.');
      return;
    }

    setIsUploading(true); // Show uploading state
    const formData = new FormData();
    formData.append('video', file);
    formData.append('description', description);

    // Simulate an upload process
    onUpload({ target: { files: [file], formData } });

    setTimeout(() => {
      setIsUploading(false); // Remove loading state
      closeModal();
    }, 2000);
  };

  const handleBackdropClick = (e) => {
    // Close the modal if the user clicks outside the modal content
    if (e.target.classList.contains('modal-overlay')) {
      closeModal();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleBackdropClick}>
      <div className="modal-content">
        <h2>Upload Video</h2>

        {/* Dropzone Area */}
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the video here...</p>
          ) : (
            <p>Drag & drop a video here, or click to select one</p>
          )}
        </div>

        {/* Show file name if a file is uploaded */}
        {file && (
          <div className="uploaded-file-info">
            <p>File selected: {file.name}</p>
          </div>
        )}

        {/* Description input */}
        <textarea
          className="description-input"
          placeholder="Add a description..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        {/* Upload button */}
        <button
          onClick={handleUploadSubmit}
          className={`upload-btn ${isUploading ? 'loading' : ''}`}
          disabled={isUploading}
        >
          {isUploading ? 'Uploading...' : 'Upload Video'}
        </button>

        {/* Close button */}
        <button onClick={closeModal} className="close-btn">
          Close
        </button>
      </div>
    </div>
  );
};

export default UploadModal;
