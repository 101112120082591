import React, { useState, useEffect } from 'react';
import '../styles/App.css';
import VideoCard from './VideoCard';  // Import the VideoCard component
import CircularProgress from '@mui/material/CircularProgress';  // MUI loading spinner

function VideoList({ refreshTrigger }) {
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [loading, setLoading] = useState(true);  // Loading state

  // Fetch all videos
  const fetchVideos = () => {
    setLoading(true);  // Start loading
    fetch(`${process.env.REACT_APP_API_URL}/api/index`)
      .then((response) => response.json())
      .then((data) => {
        setFilteredVideos(data);  // Directly set filtered videos
        setLoading(false);  // Stop loading after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching video data:', error);
        setLoading(false);  // Stop loading on error
      });
  };

  // Effect to fetch videos when the component is mounted
  useEffect(() => {
    fetchVideos();  // Fetch videos on mount
  }, []);

  // Effect to re-fetch videos when refreshTrigger changes (i.e., after an upload)
  useEffect(() => {
    if (refreshTrigger) {
      fetchVideos();  // Re-fetch the videos when refreshTrigger changes
    }
  }, [refreshTrigger]);

  return (
    <div>
      {/* Show loading spinner while loading is true */}
      {loading ? (
        <div className="loading-c">
          <CircularProgress />
        </div>
      ) : (
        <div className="video-grid-container">
          <div className="video-grid">
            {filteredVideos.length > 0 ? (
              filteredVideos.map((video, index) => (
                <VideoCard key={index} videoId={video.video} video={video} />
              ))
            ) : (
              <p>No videos found.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoList;
