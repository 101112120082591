import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; 
import '../styles/VideoInfo.css';
import CircularProgress from '@mui/material/CircularProgress';  
import ArrowBackIcon from '@mui/icons-material/ArrowBack';  
import { IconButton } from '@mui/material';
import VideoCard from './VideoCard';  // Import VideoCard component

function VideoInfo() {
  const { filename } = useParams();  
  const [videoInfo, setVideoInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSummary] = useState(false); // State for loading the AI Summary
  const [loadingIAB] = useState(false);         // State for loading the IAB
  const navigate = useNavigate();

  const fetchVideoInfo = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/video/${filename}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && !data.error) {
          setVideoInfo(data);  // Update video info with new data
        }
        setLoading(false);  
      })
      .catch((error) => {
        console.error('Error fetching video info:', error);
        setLoading(false);  
      });
  };

  useEffect(() => {
    fetchVideoInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filename]);

  const handleBackToHomepage = () => {
    navigate('/');  
  };

  // Pass this function to VideoCard to allow refreshing the data
  const refreshData = () => {
    fetchVideoInfo();
  };

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  if (!videoInfo) {
    return <p>Video not found or no information available.</p>;
  }

  return (
    <div className="video-info-page">
      <div className="header">
        <div className="title-wrapper">
          <IconButton className="back-info-button" onClick={handleBackToHomepage}>
            <ArrowBackIcon />
          </IconButton>
          <h1>{videoInfo.title}</h1> 
        </div>
      </div>

      <div className="video-info-layout">
        <div className="video-info-left">
          <VideoCard 
            videoId={videoInfo.video_id} 
            video={videoInfo}
            hideInfo={true} 
            hideSummary={true}  
            refreshData={refreshData}  // Pass refreshData callback
          />
        </div>

        <div className="summary-iab-container">
          {/* Summary Section */}
          <div className="info-box">
            <h2>Summary</h2>
            <p>
              {loadingSummary ? (
                <CircularProgress size={24} />  // Show spinner when loading summary
              ) : (
                videoInfo.summary ? videoInfo.summary : 'No summary available.'
              )}
            </p>
          </div>

          {/* IAB Section */}
          <div className="info-box">
            <h2>IAB Taxonomy</h2>
            <p>
              {loadingIAB ? (
                <CircularProgress size={24} />  // Show spinner when loading IAB
              ) : (
                videoInfo.iab_taxonomy ? videoInfo.iab_taxonomy : 'No IAB Taxonomy available.'
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );  
}

export default VideoInfo;
