import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/App.css';
import VideoCard from './VideoCard';  // Import VideoCard for consistent video layout
import CircularProgress from '@mui/material/CircularProgress';  // Import Material UI spinner
import ArrowBackIcon from '@mui/icons-material/ArrowBack';  // Material UI icon for back button

function SearchResults() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();  // Get navigate to handle back action

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('query');
    if (searchQuery) {
      setQuery(searchQuery);
      searchVideos(searchQuery);
    }
  }, [location.search]);

  const searchVideos = (query) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify({ query })
    })
      .then((response) => response.json())
      .then((data) => {
        setResults(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error searching videos:', error);
        setLoading(false);
      });
  };

  // Handle back button click
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div>
      {/* New Container for Back Button and Search Result Heading */}
      <div className="search-header">
        <button className="back-button" onClick={handleBackClick}>
          <ArrowBackIcon />
        </button>
        <h2>Search Results for "{query}"</h2>
      </div>

      <div className="video-grid-container">
        {loading ? (
          <div className="loading-c">
            <CircularProgress />
          </div>
        ) : results.length > 0 ? (
          <div className="video-grid">
            {results.map((result) => (
              <VideoCard 
                key={result.id}  // Use a unique key here
                videoId={result.video_id}  // Pass videoId as result.video
                video={result}  // Pass the whole video object
                matching_sources={result.matching_sources}  // Pass matching_sources instead of matching_embeddings
              />
            ))}
          </div>
        ) : (
          <p>No videos matched your query.</p>
        )}
      </div>
    </div>
  );
}

export default SearchResults;
