import React, { useState } from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation
import SearchBar from './SearchBar';
import '../styles/Header.css';
import { useNavigate } from 'react-router-dom';
import UploadModal from './UploadModal';

const Header = ({ onUpload }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleSearch = (query) => {
    if (query.trim()) {
      navigate(`/search?query=${query}`);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <header className="header">
      <div className="header-content">
        {/* Left side: Company logo links to home */}
        <div className="header-left">
          <Link to="/" className="company-logo">  {/* Make logo a clickable link */}
            <img src="/images/logo.png" alt="ViralMoment Logo" className="logo-image" />
          </Link>
        </div>

        <div className="header-search-bar">
          <SearchBar onSearch={handleSearch} />
        </div>

        <div className="header-upload">
          <button onClick={openModal} className="upload-btn">
            Upload Video
          </button>
        </div>
      </div>

      {/* Upload Modal */}
      {isModalOpen && (
        <UploadModal onUpload={onUpload} closeModal={closeModal} />
      )}
    </header>
  );
};

export default Header;
