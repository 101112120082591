import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VideoList from './components/VideoList';
import SearchResults from './components/SearchResults';
import VideoInfo from './components/VideoInfo';
import Header from './components/Header';
import Footer from './components/Footer';
import MobileUpload from './components/MobileUpload'; // Import the MobileUpload component

function App() {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(false); // State to trigger a refresh in VideoList

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('video', file);

    setUploading(true);
    setProgress(0);

    const xhr = new XMLHttpRequest();

    // Handle progress event
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setProgress(percentComplete);
      }
    };

    // Handle completion
    xhr.onload = () => {
      if (xhr.status === 200) {
        setProgress(100);
        setUploading(false);
        setRefreshTrigger((prev) => !prev); // Trigger refresh after successful upload
      } else {
        alert('Error uploading video.');
        setUploading(false);
      }
    };

    // Handle errors
    xhr.onerror = () => {
      alert('An error occurred during the upload.');
      setUploading(false);
    };

    // Open and send the request
    xhr.open('POST', `${process.env.REACT_APP_API_URL}/api/upload`, true);
    xhr.send(formData);
  };

  return (
    <Router>
      <Header onUpload={handleUpload} />
      <Routes>
        {/* Pass refreshTrigger to VideoList to refetch videos */}
        <Route path="/" element={<VideoList refreshTrigger={refreshTrigger} />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/video/:filename" element={<VideoInfo />} />
        <Route path="/upload" element={<MobileUpload onUpload={handleUpload} />} /> {/* New mobile upload route */}
      </Routes>

      {/* Show upload progress */}
      {uploading && (
        <div className="upload-progress">
          <p>Uploading... {progress}%</p>
        </div>
      )}

      {/* Add Footer component to display on all pages */}
      <Footer />
    </Router>
  );
}

export default App;