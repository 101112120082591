import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon component
import { faHome, faPlus } from '@fortawesome/free-solid-svg-icons'; // Import specific icons
import '../styles/Footer.css'; // Import the CSS for footer

const Footer = () => {
  const navigate = useNavigate();

  const handleUploadClick = () => {
    navigate('/upload'); // Navigate to the upload page
  };

  return (
    <div className="mobile-footer">
      <Link to="/" className="footer-icon">
        <FontAwesomeIcon icon={faHome} size="lg" /> {/* Home icon */}
      </Link>
      <button className="footer-icon upload-button" onClick={handleUploadClick}>
        <FontAwesomeIcon icon={faPlus} size="lg" /> {/* Upload icon */}
      </button>
    </div>
  );
};

export default Footer;
