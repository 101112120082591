import React, { useState } from 'react';
import '../styles/VideoCard.css';  
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';  
import InfoIcon from '@mui/icons-material/Info';  
import { IconButton, Menu, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';  
import { useNavigate } from 'react-router-dom';  

function VideoCard({ videoId, video, hideInfo, hideSummary, matching_sources, refreshData }) {  
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);  
  const [processingSummary, setProcessingSummary] = useState(false);  // AI Summary processing state
  const [processingIAB, setProcessingIAB] = useState(false);  // IAB Taxonomy processing state
  const navigate = useNavigate();  

  // Handle menu open
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle AI Summary API call
  const handleSummarize = () => {
    setProcessingSummary(true);  
    fetch(`${process.env.REACT_APP_API_URL}/api/summary/${videoId}`)
      .then(response => {
        if (response.status === 200) {
          if (typeof refreshData === 'function') {
            refreshData();  // Trigger refreshData when done if passed
          } else {
            navigate(`/video/${videoId}`);  // Navigate to video info if not in VideoInfo context
          }
        } else {
          alert('Error generating summary. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error generating summary:', error);
        alert('Error occurred. Please try again.');
      })
      .finally(() => {
        setProcessingSummary(false);  
      });

    handleMenuClose(); 
  };

  // Handle IAB Taxonomy API call
  const handleIABTaxonomy = () => {
    setProcessingIAB(true);  
    fetch(`${process.env.REACT_APP_API_URL}/api/taxonomy/${videoId}`)
      .then(response => {
        if (response.status === 200) {
          if (typeof refreshData === 'function') {
            refreshData();  // Trigger refreshData when done if passed
          } else {
            navigate(`/video/${videoId}`);  // Navigate to video info if not in VideoInfo context
          }
        } else {
          alert('Error generating IAB Taxonomy. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error generating IAB Taxonomy:', error);
        alert('Error occurred. Please try again.');
      })
      .finally(() => {
        setProcessingIAB(false);  
      });

    handleMenuClose(); 
  };

  // Handle Process Video API call
  const handleProcessVideo = () => {
    setIsProcessing(true);  // Set loading state to true

    fetch(`${process.env.REACT_APP_API_URL}/api/process_video/${videoId}`)
      .then(response => {
        if (response.status === 200) {
          alert('AI Processing Completed!');
          navigate(`/video/${videoId}`);  // Navigate to video info page on success
        } else {
          alert('Oops, something went wrong. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error processing video:', error);
        alert('Oops, something went wrong. Please try again.');
      })
      .finally(() => {
        setIsProcessing(false);  // Reset loading state
      });

    handleMenuClose(); 
  };

  // Navigate to the video info page
  const handleVideoInfo = () => {
    navigate(`/video/${videoId}`);  // Use `videoId` to navigate to the video info page
  };

  // Dynamically set the poster URL based on the processed status and the thumbnail URL
  const posterUrl = video.processed
    ? video.thumbnail  // Use the provided thumbnail if processed
    : '/images/play.png';  // Fallback to the play button image if not processed

  return (
    <div className="video-card" key={videoId}>  {/* Tie key to videoId */}
      <div className="video-wrapper">
        <div className="video-container">
          {/* Video player with dynamic poster */}
          <video controls playsinline key={videoId} poster={posterUrl}>  {/* Poster tied to videoId */}
            <source src={video.video_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {/* Transparent overlay when processing AI Summary */}
          {processingSummary && (
            <div className="processing-overlay">
              <CircularProgress size={40} style={{ color: 'white' }} />
              <span className="processing-text">Processing ...</span>
            </div>
          )}

          {/* Transparent overlay when processing IAB Taxonomy */}
          {processingIAB && (
            <div className="processing-overlay">
              <CircularProgress size={40} style={{ color: 'white' }} />
              <span className="processing-text">Processing ...</span>
            </div>
          )}

          {/* Conditionally render the summary overlay */}
          {!hideSummary && (
            <div className="video-overlay">
              <p className="video-summary">
                {video.summary ? video.summary.substring(0, 100) + '...' : 'No summary available.'}
                {video.summary && (
                  <span
                    onClick={() => navigate(`/video/${videoId}`)}
                    className="see-more-link"
                  >
                    See more
                  </span>
                )}
              </p>
            </div>
          )}
        </div>

        {/* Icons for Summarize, Info, and Process Video */}
        <div className="menu-wrapper">
          {!hideInfo && (
            <div className="icon-circle">
              <IconButton onClick={handleVideoInfo}>
                <InfoIcon />
              </IconButton>
            </div>
          )}
          <div className="icon-circle">
            <IconButton onClick={handleMenuClick}>
              <AutoAwesomeIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem disabled={video.processed || isProcessing} onClick={handleProcessVideo}>
                {isProcessing ? "Processing..." : video.processed ? "Processing Complete" : "Process Video"}
              </MenuItem>
              <MenuItem disabled={video.has_summary || processingSummary} onClick={handleSummarize}>
                {processingSummary ? "Processing..." : video.has_summary ? "AI Summary (already exists)" : "AI Summary"}
              </MenuItem>
              <MenuItem disabled={video.has_iab || processingIAB} onClick={handleIABTaxonomy}>
                {processingIAB ? "Processing..." : video.has_iab ? "IAB Taxonomy (already exists)" : "IAB Taxonomy"}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      {!hideInfo && (
        <div className="video-info">
          <strong>{video.title}</strong>

          {isProcessing && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <CircularProgress size={24} />
              <span style={{ marginLeft: '10px' }}>Processing...</span>
            </div>
          )}

          {/* Conditionally render matching sources with hover to show scores */}
          {matching_sources && matching_sources.length > 0 && (
            <div className="matching-embeddings-container">
              <p className="matched-in-text">Matched In:</p>
              {matching_sources.map((embedding, index) => (
                <div key={index} className="matching-embedding-box">
                  {embedding.source}
                  <span className="score-tooltip">Similarity Score: {embedding.score.toFixed(2)}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default VideoCard;
